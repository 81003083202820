import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import AreaData from '../../Components/ServicesPage/AreaData'



export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1><b>Golden</b> Garage Door</h1>
                </div>
            </div>
            <div className="FooterTop">

                <div className="SiteMapDiv">
                    <h1>GOLDEN GARAGE Door</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Book Garage Door Appointment Online</a>
                        <a href={`/reviews`}>Reviews</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/services">Services</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faq</a>
                        <a href="/careers">Careers</a>
                        <a href="/sitemap">Site Map</a>
                        <a href="/locations">Our Locations</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Spring">Garage Door Broken Spring</a>
                        {AreaData.map((A, index) =>
                            <a key={index} className="Header1BoxMenuBox" href={`${A.Path}`}>{A.Title}</a>
                        )}
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                           Golden Garage Door Repair
                        </a>
                        <a target='_blank' href="tel:866-363-5247">
                            866-363-5247
                        </a>
                        <a target='_blank' href="mailto:info@golden-garagedoor.net">
                            info@golden-garagedoor.net
                        </a>
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright © 2023 GOLDEN GARAGE Door. All rights reserved.</h1>
            </div>
        </div>
    )
}
